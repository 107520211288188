<template>
    <div class="card p-3 h-full">
        <AppDeleteDialog v-model:visible="showAppDeleteDialog"
            :title="`Horário de atendimento - ${diaDaSemanaSelecionadoName}`" @onConfirm="onClickDeleteRecord"
            @onClose="onClickCloseDeleteRecord">
        </AppDeleteDialog>
        <div class="flex flex-row justify-content-between">
            <div class="text-800 mb-1">
                <span class="text-primary pi pi-info-circle" v-tooltip="'Horário de Atendimento'"></span>
                Horário de Atendimento
            </div>
        </div>
        <DataTable :value="form.openingHours" class="editable-cells-table" responsiveLayout="scroll">
            <Column field="weekDay" header="Dia da semana">
                <template #body="{ data }">
                    <Dropdown v-model="data.weekDay" @change="checkDisabledOptions(data)" :options="weekDays"
                        optionValue="value" optionLabel="name" placeholder="---" class="w-full"
                        :class="{ 'p-invalid': submitted && !data.weekDay }" />
                    <small class="p-error" v-if="submitted && !data.weekDay">Campo obrigatório.</small>
                </template>
            </Column>
            <Column field="atendimentoMatutino" header="Atendimento Matutino">
                <template #body="{ data }">
                    <div class="field-checkbox">
                        <Checkbox id="binary" v-model="data.atendimentoMatutino" :binary="true"
                            @click="clearHorariosManha(data)" />
                    </div>
                </template>
            </Column>
            <Column field="startHourAm" header="Horário início AM">
                <template #body="{ data }">
                    <InputMask class="p-inputtext-sm" v-model="data.startHourAm" :disabled="!data.atendimentoMatutino"
                        mask="99:99" :class="{
                            'p-invalid':
                                submitted && data.atendimentoMatutino && (!data.startHourAm || data.startHourAm == '00:00')
                        }" />
                    <small class="p-error"
                        v-if="submitted && data.atendimentoMatutino && (!data.startHourAm || data.startHourAm == '00:00')">
                        Campo não pode ser vazio ou igual a '00:00'
                    </small>
                </template>
            </Column>
            <Column field="endHourAm" header="Horário fim AM">
                <template #body="{ data }">
                    <InputMask class="p-inputtext-sm" v-model="data.endHourAm" :disabled="!data.atendimentoMatutino"
                        mask="99:99" :class="{
                            'p-invalid': submitted && data.atendimentoMatutino && (!data.endHourAm || data.endHourAm == '00:00')
                        }" />
                    <small class="p-error"
                        v-if="submitted && data.atendimentoMatutino && (!data.endHourAm || data.endHourAm == '00:00')">
                        Campo não pode ser vazio ou igual a '00:00'
                    </small>
                </template>
            </Column>
            <Column field="serviceTypeAm" header="Tipo atendimento AM">
                <template #body="{ data }">
                    <Dropdown v-model="data.serviceTypeAm" :options="serviceTypes" :disabled="!data.atendimentoMatutino"
                        optionValue="value" optionLabel="name" placeholder="---" class="w-full"
                        :class="{ 'p-invalid': submitted && data.atendimentoMatutino && !data.serviceTypeAm }" />
                    <small class="p-error" v-if="submitted && data.atendimentoMatutino && !data.serviceTypeAm">Campo
                        obrigatório.</small>
                </template>
            </Column>
            <Column>
                <template #body="{ data }">
                    <Button :disabled="!data.id || !data.atendimentoMatutino" v-tooltip.bottom="'Exames não atendidos AM'"
                        icon="pi pi-book" class="p-button-text" @click="examesNaoAtendidosMatutino(data)" />
                </template>
            </Column>

            <Column field="atendimentoVespertino" header="Atendimento Vespertino">
                <template #body="{ data }">
                    <div class="field-checkbox">
                        <Checkbox id="binary" v-model="data.atendimentoVespertino" :binary="true"
                            @click="clearHorariosTarde(data)" />
                    </div>
                </template>
            </Column>
            <Column field="startHourPm" header="Horário início PM">
                <template #body="{ data }">
                    <InputMask class="p-inputtext-sm" v-model="data.startHourPm" mask="99:99"
                        :disabled="!data.atendimentoVespertino" :class="{
                            'p-invalid':
                                submitted && data.atendimentoVespertino && (!data.startHourPm || data.startHourPm == '00:00')
                        }" />
                    <small class="p-error"
                        v-if="submitted && data.atendimentoVespertino && (!data.startHourPm || data.startHourPm == '00:00')">
                        Campo não pode ser vazio ou igual a '00:00'
                    </small>
                </template>
            </Column>
            <Column field="endHourPm" header="Horário fim PM">
                <template #body="{ data }">
                    <InputMask class="p-inputtext-sm" v-model="data.endHourPm" mask="99:99"
                        :disabled="!data.atendimentoVespertino" :class="{
                            'p-invalid': submitted && data.atendimentoVespertino && (!data.endHourPm || data.endHourPm == '00:00')
                        }" />
                    <small class="p-error"
                        v-if="submitted && data.atendimentoVespertino && (!data.endHourPm || data.endHourPm == '00:00')">
                        Campo não pode ser vazio ou igual a '00:00'
                    </small>
                </template>
            </Column>
            <Column field="serviceTypePm" header="Tipo atendimento PM ">
                <template #body="{ data }">
                    <Dropdown v-model="data.serviceTypePm" :options="serviceTypes" class="w-full"
                        :disabled="!data.atendimentoVespertino" optionValue="value" optionLabel="name" placeholder="---"
                        :class="{ 'p-invalid': submitted && data.atendimentoVespertino && !data.serviceTypePm }" />
                    <small class="p-error" v-if="submitted && data.atendimentoVespertino && !data.serviceTypePm">Campo
                        obrigatório.</small>
                </template>
            </Column>
            <Column>
                <template #body="{ data }">
                    <Button :disabled="!data.id || !data.atendimentoVespertino" v-tooltip.bottom="'Exames não atendidos PM'"
                        icon="pi pi-book" class="p-button-text" @click="examesNaoAtendidosVespertino(data)" />
                </template>
            </Column>
            <Column>
                <template #body="{ data }">
                    <Button icon="pi pi-times" class="p-button-text" @click="removerDiaAtendimento(data)" />
                </template>
            </Column>
        </DataTable>
        <Button label="Adicionar horário" icon="pi pi-plus" class="p-button-text w-3 ml-auto" @click="adicionarHorario" />
        <ExamesNaoAtendidosDialog v-if="showExamesNaoAtendidos" :visible="showExamesNaoAtendidos" :recordPrestador="form"
            :periodo="periodo" :record="record" @onClose="showExamesNaoAtendidos = false" />
        <div class="flex flex-row justify-content-end">
            <Button label="Voltar" icon="pi pi-arrow-left" class="p-button-outlined w-2 mr-3" @click="$router.go(-1)" />
            <Button label="Salvar" icon="pi pi-check" class="p-button-primary w-2" @click="save()" />
        </div>
    </div>
</template>
<script>
import BaseService from '../../../../services/BaseService';
import { showError, showSuccess } from '@/utils/Toast';
import EnumPeriodoAMePM from '../../../../enums/EnumPeriodoAMePM';
import ExamesNaoAtendidosDialog from './ExamesNaoAtendidosDialog.vue';
export default {
    components: { ExamesNaoAtendidosDialog },
    data() {
        return {
            form: {},
            submitted: false,
            weekDays: [
                { value: 'DOM', name: 'Domingo' },
                { value: 'SEG', name: 'Segunda' },
                { value: 'TER', name: 'Terça' },
                { value: 'QUA', name: 'Quarta' },
                { value: 'QUI', name: 'Quinta' },
                { value: 'SEX', name: 'Sexta' },
                { value: 'SAB', name: 'Sábado' }
            ],
            serviceTypes: [
                { value: 'ORDEM_CHEGADA', name: 'Ordem de chegada' },
                { value: 'HORARIO_MARCADO', name: 'Horário marcado' }
            ],
            periodo: null,
            periodoEnum: EnumPeriodoAMePM,
            record: null,
            showExamesNaoAtendidos: false,
            showAppDeleteDialog: false,
            diaDaSemanaSelecionado: {}
        }
    },
    computed: {
        diaDaSemanaSelecionadoName() {
            return this.weekDays.find((p) => p.value == this.diaDaSemanaSelecionado?.weekDay)?.name ?? '';
        }
    },
    async mounted() {
        this.$service = new BaseService('/suppliers');
        this.$serviceOpeningHours = new BaseService('/supplier/opening-hours');
        await this.loadPrestador();
    },
    methods: {
        async loadPrestador() {
            try {
                const { data } = await this.$service.findById(this.$route.params.id);
                this.form = data;
            } catch (error) {
                showError(this.$toast, error);
            }
        },
        async save() {
            this.submitted = true;

            const hasError = await this.hasError();
            if (hasError) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Horários inválidos!',
                    detail: 'Verifique os campos em destaque!',
                    life: 3000
                });
                return;
            }
            try {
                await this.$serviceOpeningHours.save({ supplier: this.form, openingHours: this.form.openingHours });
                showSuccess(this.$toast, 'Horário de atendimento salvo com sucesso!');
                await this.loadPrestador();
            } catch (error) {
                const detailsMessages = error?.response?.data?.details?.response?.message;
                const message = detailsMessages.map((el) => {
                    return el.slice(15)
                })
                const messages = Array.isArray(message) ? message[0] : message;
                this.$toast.add({ severity: 'error', summary: 'Ocorreu algum problema!', detail: messages, life: 3000 });
            }
            this.submitted = false;
        },
        async hasError() {
            this.submitted = true;
            let horarioIncorreto = false;
            this.form.openingHours.forEach((horario) => {
                if (
                    !horario?.weekDay ||
                    (horario?.atendimentoMatutino && (!horario?.endHourAm || horario?.endHourAm == '00:00' || !horario?.serviceTypeAm)) ||
                    (horario?.atendimentoVespertino && (!horario?.endHourPm || horario?.endHourPm == '00:00' || !horario?.serviceTypePm))
                ) {
                    horarioIncorreto = true;
                }
            });

            if (horarioIncorreto) {
                return true;
            }
        },
        checkDisabledOptions(option) {
            const retorno = this.form?.openingHours?.filter((r) => r?.weekDay == option?.weekDay);
            if (retorno.length > 1) {
                option.weekDay = null;
                this.$toast.add({ severity: 'error', summary: 'Este dia da semana ja foi selecionado!', life: 3000 });
            }
        },
        clearHorariosManha(horario) {
            horario.serviceTypeAm = null;
            horario.startHourAm = '00:00';
            horario.endHourAm = '00:00';
            return horario;
        },
        clearHorariosTarde(horario) {
            horario.startHourPm = '00:00';
            horario.endHourPm = '00:00';
            horario.serviceTypePm = null;
            return horario;
        },
        async removerDiaAtendimento(data) {
            this.showAppDeleteDialog = true;
            this.diaDaSemanaSelecionado = data;
        },
        onClickCloseDeleteRecord() {
            this.showAppDeleteDialog = false;
            this.diaDaSemanaSelecionado = {};
        },
        async onClickDeleteRecord() {
            if (this.diaDaSemanaSelecionado.id) {
                try {
                    await this.$serviceOpeningHours.remove(this.diaDaSemanaSelecionado.id);
                    this.$toast.add({ severity: 'success', summary: 'Horário removido com sucesso! ', life: 5000 });
                } catch (err) {
                    const message = err.response?.data?.message;
                    this.$toast.add({ severity: 'error', summary: 'Problemas ao salvar o prestador! ' + message, life: 5000 });
                }
            }
            await this.loadPrestador();
            this.showAppDeleteDialog = false
        },
        adicionarHorario() {
            this.form.openingHours = this.form.openingHours || [];
            this.form.openingHours.push({ startHourAm: '00:00', endHourAm: '00:00', startHourPm: '00:00', endHourPm: '00:00' });
        },
        examesNaoAtendidosMatutino(data) {
            this.periodo = this.periodoEnum.MANHA;
            this.record = data;
            this.showExamesNaoAtendidos = true;
        },
        examesNaoAtendidosVespertino(data) {
            this.periodo = this.periodoEnum.TARDE;
            this.record = data;
            this.showExamesNaoAtendidos = true;
        },
    },
}
</script>