<template>
    <Dialog :breakpoints="{ '1000px': '60vw', '650px': '80vw' }" :style="{ width: '60vw' }"
        :header="`Exames não atendidos por ${recordPrestador.name} (${diaDaSemana} / ${periodo})`" :closable="false">
        <div class="w-full">
            <div class="grid">
                <div class="field col-12 md:col-11">
                    <label for="name">Procedimentos</label>
                    <MultiSelectProcedimentos ref="multiSelectProcedimentos" :filtrosExtras="filtrosExtrasExames"
                        class="w-full" id="examesFuncionario" v-model="procedimentos" />
                </div>
                <div class="field col-12 md:col-1">
                    <Button icon="pi pi-check" class="p-button-text p-button-primary" v-tooltip="'Salvar'"
                        style="margin-top: 24px" @click="save" :loading="loadingSave" />
                </div>
            </div>
        </div>
        <Fieldset :legend="`Exames não atendidos - (${diaDaSemana} / ${periodo})`" style="margin-top: 10px">
            <div class="flex flex-column mb-4 md:flex-row md:justify-content-between md:align-items-center">
                <div class="p-fluid mt-2 w-full md:mt-0 md:w-5">
                    <Chips v-model="filter" placeholder="Pesquisar" @add="load" @remove="load" />
                </div>
            </div>
            <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.3em" />
            <DataTable dataKey="id" :value="records" :row-hover="true" class="p-datatable-sm" :scrollable="true"
                scrollHeight="280px" responsiveLayout="stack">
                <template #empty>
                    Não há exames não atendidos configurados ainda!
                </template>
                <template #loading> Carregando registros. Aguarde ... </template>
                <Column :sortable="true" field="procedimento.name" header="Procedimento"></Column>
                <Column field="periodo" header="Período"></Column>
                <Column bodyClass="text-right" style="flex: 0 0 4rem">
                    <template #body="slotProps">
                        <Button icon="pi pi-trash" class="p-button-text p-button-danger" v-tooltip="'Excluir'"
                            @click="confirmDeleteRecord(slotProps.data)" />
                    </template>
                </Column>
            </DataTable>
        </Fieldset>
        <template #footer>
            <div class="flex flex-row justify-content-end">
                <Button label="Voltar" icon="pi pi-arrow-left" class="p-button-outlined w-2" @click="onClose()" />
            </div>
        </template>
    </Dialog>
</template>
<script>
import MultiSelectProcedimentos from './MultiSelectProcedimentos.vue';
import EnumDiaDaSemana from '../../../../enums/EnumDiaDaSemana';
import BaseService from '../../../../services/BaseService';

export default {
    props: ['record', 'periodo', 'recordPrestador'],
    emits: ['onClose'],
    components: { MultiSelectProcedimentos },
    data() {
        return {
            records: [],
            procedimentos: [],
            loading: false,
            submitted: false,
            loadingSave: false,
            showAppDeleteDialog: false,
            enumDiaDaSemana: EnumDiaDaSemana,
            form: {},
            filtrosExtrasExames: null,
            filter: []
        }
    },
    computed: {
        diaDaSemana() {
            switch (this.record.weekDay) {
                case this.enumDiaDaSemana.SEG:
                    return 'SEGUNDA-FEIRA';
                case this.enumDiaDaSemana.TER:
                    return 'TERÇA-FEIRA';
                case this.enumDiaDaSemana.QUA:
                    return 'QUARTA-FEIRA';
                case this.enumDiaDaSemana.QUI:
                    return 'QUINTA-FEIRA';
                case this.enumDiaDaSemana.SEX:
                    return 'SEXTA-FEIRA';
                case this.enumDiaDaSemana.SAB:
                    return 'SABADO';
                case this.enumDiaDaSemana.DOM:
                    return 'DOMINGO';
                default:
                    return ('Carregando ...')
            }
        },
    },
    watch: {
        records() {
            this.filtrosExtrasExames = {
                mostrarApenasProcedimentosNaoSelecionados: this.records.map(p => p.procedimento.id) ?? []
            };
        },
        filtrosExtrasExames() {
            this.$refs?.multiSelectProcedimentos?.reLoad(this.filtrosExtrasExames);
        }
    },
    async created() {
        this.$service = new BaseService('/prestador/exame-nao-atendido');
        this.load();

    },
    methods: {
        async save() {
            this.loadingSave = true;
            this.submitted = true;
            try {
                this.form = {
                    procedimentos: this.procedimentos,
                    prestadorId: this.recordPrestador.id,
                    supplierOpeningHoursId: this.record.id,
                    periodo: this.periodo
                }
                const { data } = await this.$service.save(this.form);
                this.$toast.add({ severity: 'success', summary: 'Exames não atendidos cadastrados com sucesso!', life: 3000 });
                if (data.nomesRegistrosExistentes?.length) {
                    this.$toast.add({ severity: 'info', summary: 'Exames já cadastrados:', detail: `${data.nomesRegistrosExistentes.join(', ')}`, life: 5000 });
                }
                this.submitted = false;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro ao salvar exames não atendidos, ' + error.response?.data?.message, life: 5000 });
            }
            this.loadingSave = false;
            this.filter = [];
            this.form = {};
            this.procedimentos = [];
            this.load();
        },
        onClose() {
            this.$emit('onClose');
        },
        async load() {
            this.loading = true;
            try {
                const { data } = await this.$service.findAll({
                    filter: this.filter,
                    filtrosExtras: {
                        prestadorId: this.recordPrestador.id,
                        supplierOpeningHoursId: this.record.id,
                        periodo: this.periodo,
                    }
                });
                this.records = data.items
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: error.response.data.message, life: 5000 });
            }
            this.loading = false;
        },
        async confirmDeleteRecord(record) {
            try {
                await this.$service.remove(record.id);
                this.load();
                this.$toast.add({ severity: 'success', summary: 'Exames não atendidos excluído com sucesso!', life: 3000 });
            } catch (err) {
                this.$toast.add({ severity: 'error', summary: 'Erro ao excluir exames não atendidos!', life: 3000 });
            }
        },
    },
}
</script>
<style lang="scss" scoped>
:deep(::-webkit-scrollbar-track) {
    background-color: #F4F4F4;
}

:deep(::-webkit-scrollbar) {
    width: 6px;
    background: #F4F4F4;
}

:deep(::-webkit-scrollbar-thumb) {
    background: #ced4da;
    border-radius: 15px;
}
</style>