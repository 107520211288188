<template>
    <AppMultiselect
        ref="appMultiselect"
        v-model="value" 
        optionLabel="name" 
        dataKey="id"
        placeholder="Selecione o exame..." 
        :service="$service"
        :filtrosExtras="filtrosExtras"
        @change="change">
    </AppMultiselect>
</template>
<script>
import BaseService from '@/services/BaseService';
export default {
    props: {
        modelValue: {
            type: Object || Array
        },
        filtrosExtras: {
            type: Object || Array,
            default: {}
        },
        itemSize: {
            type: Number,
            default: 20
        },
        change: {
            type: Function
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            $service: null
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    async created() {
        this.$service = new BaseService('/procedures');
    },
    methods: {
        reLoad(filtrosExtras) {
            this.$refs.appMultiselect.load({limit: 20, filter: null, filtrosExtras})
        }
    },
};
</script>
